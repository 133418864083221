<template>
  <section id="contact">
    <div class="container">
      <div v-if="!formSubmitMessage" class="row pt-1">
        <div class="col-12">
          <h2 class=""><span :class="(data.global['headerIcons']) ? 'pr-3' : 'pr-0'"><b-icon v-if="data.global['headerIcons']" icon="chat-left-text" class="text-primary" aria-hidden="true"></b-icon></span>{{ data.json.title }} </h2>
          <p>{{ data.json['paragraph'] }}</p>
        </div>
        <div class="col-12">
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <label for="name">Your Name *</label>
              <input v-model="name" type="text" class="form-control" id="name" placeholder="Firstname & Surname">
              <div class="required">This is a required field</div>
            </div>
            <div class="form-group">
              <label for="email-address">Email address *</label>
              <input v-model="email" type="email" class="form-control" id="email" placeholder="name@example.com">
              <div class="required">This is a required field</div>
            </div>
            <div class="form-group">
              <label for="hear-about">How did you hear about us? *</label>
              <select v-model="select" class="form-control" id="hear-about">
                <option v-for="(option, index) in data.json['selectOptions']" :key="index">{{ option }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="message">Your Message *</label>
              <textarea v-model="message" class="form-control" id="message" rows="3"></textarea>
              <div class="required">This is a required field</div>
            </div>

            <input v-model="messageIfNeededContact" id="messageIfNeededContact" type="text">

            <button type="submit" class="btn btn-primary">Send</button>
          </form>
        </div>
      </div>
      <div class="row pt-1">
        <div class="formSubmitMessage"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'Contact',
  props: {},
  data() {
    return {
      name: '',
      email: '',
      select: 'Friend of Family member',
      message: '',
      messageIfNeededContact: '',
      formSubmitMessage: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.messageIfNeededContact !== '') {
        return
      }
      if (this.name === '' || this.email === '' || this.message === '') {
       const arr = Array.from(this.$el.getElementsByClassName('required'));
        arr.forEach((el) => {
         el.style.display = 'block';
       })
      }
      else {
        try {
          const { data } = await axios.post("contact.php", {name: this.name, email: this.email, select: this.select, message: this.message});
          console.log(data);
          this.formSubmitMessage = true;
          const arr = Array.from(this.$el.getElementsByClassName('formSubmitMessage'));
          arr[0].innerHTML = '' +
              '<div class="text-center">' +
              '<h3 style="color: green;">Thank you for sending your information</h3>' +
              '<p>Your email is: <b>'+this.email+'</b></p>' +
              '</div>'
          arr[0].style.display = 'block';
        }
        catch(err) {
          this.formSubmitMessage = true;
          const arr = Array.from(this.$el.getElementsByClassName('formSubmitMessage'));
          arr[0].innerHTML = '' +
              '<div class="text-center">' +
                '<h3 style="color: red;">There seems to be an issue sending the form.</h3>' +
                '<p>Please contact directly at <a href="mailto:'+this.data.global.email+'">'+this.data.global.email+'</a></p>' +
              '</div>'
          arr[0].style.display = 'block';
        }
      }
    }
  },
  computed: {
    data() {
      const cJson = this.$webData.components.find((e) => e.name === 'Contact');
      return {
        json: cJson,
        global: this.$webData.global
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input[type="text"]#messageIfNeededContact { display: none; }
.container {
  .row {
    padding-top: 0;
  }
  .required {
    display: none;
    color: red;
    font-size: 0.9em;
  }
  .formSubmitMessage {
    display: none;
    margin: auto;
  }
}
</style>
